import { getCookie } from './cookies';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import {
  TOKEN_COOKIE_NAME,
  OWNER_LEGAL_ENTITY_TYPES
} from './constants';
import getConfig from 'next/config';
import _isValidDate from 'date-fns/isValid';

const cookie = require('cookie');
const jwtDecode = require('jwt-decode');

/* ----------------------------------------------------------------------1------- */

export function correctNum(num) {
  return num < 10 ? `0${num}` : num;
}

/* ----------------------------------------------------------------------------- */

export function getHumanDate(timestamp) {
  const date = new Date(timestamp);

  const day = correctNum(date.getDate());
  const month = correctNum(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

/* ----------------------------------------------------------------------------- */

export function getHumanTime(timestamp) {
  const date = new Date(timestamp);

  const hours = correctNum(date.getHours());
  const minutes = correctNum(date.getMinutes());

  return `${hours}:${minutes}`;
}

export function getHumanDateTime(timestamp) {
  return `${getHumanDate(timestamp)} ${getHumanTime(timestamp)}`;
}

/* ----------------------------------------------------------------------------- */

export function getLocaleDate(timestamp, locale = 'ru', format = 'default') {
  const formatConfigs = {
    'default': { day: 'numeric', month: 'numeric', year: 'numeric' },
    'fullWithTime': { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' },
    'fullDate': { day: 'numeric', month: 'long', year: 'numeric' },
    'monthYear': { month: 'long', year: 'numeric' },
    'weekRage': { day: 'numeric', month: 'numeric', year: 'numeric' },
  };

  if (format === 'weekRage') {
    return (new Date(timestamp).toLocaleDateString(locale, formatConfigs['weekRage'])
      + ' - '
      + new Date(+timestamp + 1000 * 60 * 60 * 24 * 6).toLocaleDateString(locale, formatConfigs['weekRage']));
  }

  return new Date(timestamp).toLocaleDateString(locale, formatConfigs[format]);
}

/* ----------------------------------------------------------------------------- */

export function isDev() {
  const { ENV } = getConfig().publicRuntimeConfig;

  return ENV === 'development';
}

export function isProd() {
  const { ENV } = getConfig().publicRuntimeConfig;

  return ENV === 'production';
}

export function isProdAPI() {
  const { API_URL } = getConfig().publicRuntimeConfig;

  return API_URL === 'http://zencar-backend-prod:3000';
}

/* ----------------------------------------------------------------------------- */

export function getWidgetBaseUrl() {
  const { API_URL } = getConfig().publicRuntimeConfig;

  return API_URL === 'http://zencar-backend-prod:3000' ? 'https://client.zencar.tech' : 'https://zencar-client-dev.dev.zencar.tech';
}

/* ----------------------------------------------------------------------------- */

export function getToken() {
  return getCookie(TOKEN_COOKIE_NAME);
}

/* ----------------------------------------------------------------------------- */

export function getAuthHeader() {
  return {
    'Authorization': `Bearer ${getToken()}`,
  };
}

/* ----------------------------------------------------------------------------- */

export function getUserFromCookie() {
  const token = getToken();

  if (token) {
    return jwtDecode(token);
  } else {
    return false;
  }
}

/* ----------------------------------------------------------------------------- */

export function getTokenFromRequest(req) {
  let token = null;

  if (req.headers.cookie) {
    token = cookie.parse(req.headers.cookie)[TOKEN_COOKIE_NAME];
  }

  return token;
}

export function getUserFromRequestToken(req) {
  let token;

  if (req) {
    if (req.headers.cookie) {
      token = cookie.parse(req.headers.cookie)[TOKEN_COOKIE_NAME];
    }
  } else {
    token = getToken();
  }

  if (token) {
    return jwtDecode(token);
  } else {
    return false;
  }
}

/* ----------------------------------------------------------------------------- */

export function getUserName(name) {
  if (typeof name === 'string') {
    return name;
  } else if (name && typeof name === 'object') {
    const { first, last, middle } = name;
    return compact([last, first, middle]).join(' ');
  } else {
    return ''
  }
}

/* ----------------------------------------------------------------------------- */

export function getShortUserName(name) {
  const { first, last, middle } = name;
  let shortFirst, shortMiddle;

  if (first) {
    shortFirst = first.substr(0, 1).toUpperCase() + '.';
  }

  if (middle) {
    shortMiddle = middle.substr(0, 1).toUpperCase() + '.';
  }

  return compact([last, shortFirst, shortMiddle]).join(' ');
}

/* ----------------------------------------------------------------------------- */

export function isLocalStorageAvailable() {
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
    return true;
  } catch (e) {
    return false;
  }
}

/* ----------------------------------------------------------------------------- */

export function roundNumber(num, accuracy) {
  return Math.round(num * 10 ** accuracy) / (10 ** accuracy);
}

/* ----------------------------------------------------------------------------- */

export function formatNumber(num) {
  const roundedNum = roundNumber(num, 2);
  const _num = roundedNum.toString().split('.');

  if (_num[0].length > 3) {
    const formattedNum = _num[0].replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

    if (_num[1]) {
      return `${formattedNum}.${_num[1]}`;
    } else {
      return `${formattedNum}`;
    }
  } else {
    return roundedNum;
  }
}

/* ----------------------------------------------------------------------------- */

export function formatMoney(num) {
  if (num && num.amount) {
    return num.amount + ' ₽';
  }
}

/* ----------------------------------------------------------------------------- */

export function numberToTime(num) {
  const totalMinutes = num * 60;
  const hours = Math.floor(num);
  const minutes = totalMinutes % 60;

  return { hours, minutes, asString: `${correctNum(hours)}:${correctNum(minutes)}` };
}

/* ----------------------------------------------------------------------------- */

export function errorMessagesToStrings(errors) {
  return `
    <ul>
      ${errors?.map((err) => {
    if (err.type === 'ValidationProblem' && err.invalidInputs && !isEmpty(err.invalidInputs)) {
      const inputErrors = err.invalidInputs.map((invalidInput) => {
        let inputErrorSting = `${invalidInput.property}: `;
        for (let key in invalidInput.constraints) {
          inputErrorSting += `${invalidInput.constraints[key]}<br/>`
        }
        return `<li>${inputErrorSting}</li>`
      });

      return inputErrors.join('');
    } else {
      const code = err.code ? `${err.code}: ` : '';
      return `<li>${code}${err.message || err.detail}</li>`;
    }
  }).join('')}
    </ul>
  `;
}

/* ----------------------------------------------------------------------------- */

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

/* ----------------------------------------------------------------------------- */

export function clearObjectFromEmpties(object) {
  const withNumValues = pickBy(object, isNumber);
  const omitted = omitBy(object, isEmpty);

  return { ...omitted, ...withNumValues };
}

export function emptyStringToNull(model) {
  return mapValues(model, (value) => {
    if (value === '' || value === undefined) {
      value = null;
    }
    return value;
  });
}

/* ----------------------------------------------------------------------------- */

export function getHumanAddress(address) {
  return values(clearObjectFromEmpties(omit(address, '__typename'))).join(', ');
}

/* ----------------------------------------------------------------------------- */

export function composeProviders(contexts, children) {
  return contexts.reduce((acc, [Provider, value]) => {
    return <Provider initialState={value}>{acc}</Provider>;
  }, children);
}

/* ----------------------------------------------------------------------------- */

export function omitTypename(key, value) {
  return key === '__typename' ? undefined : value;
}

/* ----------------------------------------------------------------------------- */

export function clearDataFromTypename(data) {
  return JSON.parse(JSON.stringify(data), omitTypename);
}

/* ----------------------------------------------------------------------------- */

export function getDomainForCookie() {
  const { hostname } = document.location;

  if (hostname !== 'localhost') {
    const split = hostname.split('.');

    return `.${split[split.length - 2]}.${split[split.length - 1]}`;
  }

  return null;
}

/* ----------------------------------------------------------------------------- */

export function log(event = '', json = {}) {
  const value = {
    datetime: new Date().toISOString(),
    event,
    ...json,
  };

  console.log(`/${JSON.stringify(value)}/`);

  return value;
}

/* ----------------------------------------------------------------------------- */

export function getRolesText(roles, t) {
  let result = '';

  for (let i = 0; i < roles.length; i++) {
    result += t(`common.user.roles.${roles[i]}`);
    if (i < roles.length - 1) {
      result += ', ';
    }
  }

  return result;
}

/* ----------------------------------------------------------------------------- */

export function getPayrollSchemeText(scheme, t) {
  const type = scheme.__typename;
  const value = scheme.percentPayment || scheme.fixedPayment?.amount || 0;
  let withDiscountPath = '';
  if (scheme.withDiscount) {
    withDiscountPath = 'Discount';
  }
  let calculateDate = '';
  if (scheme.settlementDay) {
    calculateDate = t(`employee.paymentSchemes.schemeCalcDay`, { value: scheme.settlementDay });
  }

  return t(`employee.paymentSchemes.${type}${withDiscountPath}`, { value }) + calculateDate;
}

/* ----------------------------------------------------------------------------- */

export function getStarOfYear(date) {
  return new Date(new Date(date).getFullYear(), 0, 1);
}

/* ----------------------------------------------------------------------------- */

export function getEndOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
}

/* ----------------------------------------------------------------------------- */

export function getArrayFromNumber(endNumber, startNumber = 0) {
  let result = [];
  for (let i = startNumber; i <= endNumber; i++) {
    result.push(i);
  }

  return result;
}

export function required(fieldName, value) {
  if (!value) {
    return 'Поле \"' + fieldName + '\" обязательно для заполнения.';
  }
}

export function positiveNumber(fieldName, value) {
  if (!(isNumber(value) && value > 0)) {
    return 'Поле \"' + fieldName + '\" должно быть положительным числом.';
  }
}

/* ----------------------------------------------------------------------------- */

export function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

/* ----------------------------------------------------------------------------- */

export function groupBy(data, groupingProp) {
  const map = new Map();
  data.forEach(entry => {
    const groupKey = entry[groupingProp];
    const list = map.get(groupKey);
    if (!list) {
      map.set(groupKey, [entry]);
    } else {
      list.push(entry);
    }
  });
  return [...map.values()];
}

/* ----------------------------------------------------------------------------- */

export function isValidDate(rawValue) {
  const value = rawValue || '';
  const reverse = value.split('.').reverse().join('-');
  const date = new Date(reverse);

  return _isValidDate(date);
}

/* ----------------------------------------------------------------------------- */

export function ownerIsLegalEntity(owner) {
  return owner.type && OWNER_LEGAL_ENTITY_TYPES.includes(owner.type);
}


/* ----------------------------------------------------------------------------- */

// Get first day of current month in ISO string
export function getFirstDayCurrentMonthMidnight(period) {
  const d = new Date();
  d.setFullYear(d.getFullYear());
  if (getLastDayPreviousMonth() === getMondayCurrentWeek() && period === "week") {
    d.setDate(0);
  } else {
    d.setDate(1);
  }
  d.setUTCHours(0, 0, 0, 0);

  return d.toISOString();
}

export function getFirstDayCurrentMonth() {
  const d = new Date();
  d.setFullYear(d.getFullYear());
  d.setDate(0)
  d.setUTCHours(21, 0, 0, 0);

  return d.toISOString();
}

/* ----------------------------------------------------------------------------- */

// Get today date in ISO string
export function getToday() {
  const d = new Date();
  const today = d.getDate();
  d.setDate(today);
  d.setUTCHours(0, 0, 0, 0);

  return d.toISOString();
}

/* ----------------------------------------------------------------------------- */

// Get tomorrow date in ISO string
export function getTomorrow() {
  const d = new Date();
  const today = d.getDate();
  d.setDate(today + 1);
  d.setUTCHours(0, 0, 0, 0);

  return d.toISOString();
}


export function getFirstDayNextMonth() {
  const date = new Date();
  const firstDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 2);
  firstDayNextMonth.setUTCHours(0, 0, 0, 0);
  return firstDayNextMonth.toISOString();
}


/* ----------------------------------------------------------------------------- */

export function getDomain(url) {
  return url.replace('www.', '').replace('.com', '').replace('.ru', '');
}

export function isProfileSubscribed(expiredDateProfile) {
  return new Date(expiredDateProfile).getTime() >= new Date().getTime()
}
/* ----------------------------------------------------------------------------- */
export function translit(word) {

  var answer = '';

  var converter = {

    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',

    'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',

    'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',

    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',

    'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',

    'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',

    'э': 'e', 'ю': 'yu', 'я': 'ya',



    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',

    'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',

    'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',

    'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',

    'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',

    'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',

    'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya', ' ': '_'

  };


  for (var i = 0; i < word.length; ++i) {

    if (converter[word[i]] == undefined) {

      answer += word[i];

    } else {

      answer += converter[word[i]];

    }

  }

  return answer;
}

/* ----------------------------------------------------------------------------- */
// Get days of current week
const transformDateToCurrentDaysWeek = (day) => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  const offset = today.getDay() - day;

  const d = new Date(today);
  d.setDate(d.getDate() - offset);
  return d.toISOString();
};

export const daysOfWeek = () => [1, 2, 3, 4, 5, 6, 7].map(day => transformDateToCurrentDaysWeek(day));

/* ----------------------------------------------------------------------------- */

// Get first day if weeks in current month

export function getNextMonday() {
  const dateCopy = new Date(new Date().getTime());

  const nextMonday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)
    )
  );

  nextMonday.setUTCHours(0, 0, 0, 0);
  return nextMonday.toISOString()
}

/* ----------------------------------------------------------------------------- */

// get mondays of current month
export function getMondaysCurrentMonth() {
  const d = new Date(),
    month = d.getMonth(),
    mondays = [];

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
    d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getMonth() === month) {
    const date = new Date(d.getTime());
    date.setUTCHours(0, 0, 0, 0);
    mondays.push(new Date(date).toISOString());
    d.setDate(d.getDate() + 7);
  }

  return mondays;
}


/* ----------------------------------------------------------------------------- */

// Get monday of current week
export function getMondayCurrentWeek(year = new Date().getFullYear()) {
  const today = new Date();
  today.setFullYear(year);
  today.setUTCHours(0, 0, 0, 0);
  const first = today.getDate() - today.getDay() + 1;

  return new Date(today.setDate(first)).toISOString();
}

/* ----------------------------------------------------------------------------- */

// Get first day of current year

export function getFirstDayYear(year = new Date().getFullYear()) {
  const d = new Date();
  d.setFullYear(year);
  d.setMonth(0);
  d.setDate(1);
  d.setUTCHours(0, 0, 0, 0);

  return d.toISOString();
}


/* ----------------------------------------------------------------------------- */

export function getLastDayPreviousMonth() {
  const date = new Date();
  const d = new Date(date.getFullYear(), date.getMonth());
  d.setUTCHours(0, 0, 0, 0);
  return d.toISOString();
}