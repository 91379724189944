import has from 'lodash/has';

const VINPattern = /[A-HJ-NPR-Z0-9]{13}[0-9]{4}/;

const RULE = {
  'А': 'A',
  'В': 'B',
  'С': 'C',
  'Е': 'E',
  'К': 'K',
  'М': 'M',
  'Н': 'H',
  'О': 'O',
  'Р': 'P',
  'Т': 'T',
  'У': 'Y',
  'Х': 'X',
  'О': '0',
  'O': '0',
  'I': '1',
  'Q': '0'
};

export function fixVIN(vin) {
  let _vin = `${vin || ''}`.toUpperCase().split('');

  _vin = _vin.map(char => {
    return has(RULE, char) ? RULE[char] : char;
  });

  return _vin.join('');
};

export function validate(vin) {
  if (vin !== null && vin !== '') {
    return VINPattern.test(vin);
  } else {
    return true
  }

};